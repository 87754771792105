<template>
  <div>
    <iframe
      :src='frame'
      width='100%'
      height='1200px'
      frameborder='0'
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import {
  sumdatetime, sumdateUpper,
} from '@/plugins/filters'
import 'jspdf-autotable'
import shop from '@/api/systemSetting/shop'
import booze_data from '@/@fake-db/data/booze_data.json'
import smoking_data from '@/@fake-db/data/smoking_data.json'

export default {
  setup() {
    const moment = require('moment')
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(13)
    const fontBody = ref(12)
    const shop_print_type = ref(0)
    const boozeList = ref(booze_data.data)
    const smokingList = ref(smoking_data.data)
    const shopData = ref({})
    const frame = ref('')
    const countPage = ref(0)

    shop.shopGet().then(res => {
      shop_print_type.value = route.value.query.shop_print_type
      shopData.value = res
      printsOpdCard()
    })

    const printsOpdCard = () => {
      prints.printsOpdCard({
        opd_id: route.value.query.id.split(','),
      }).then(res => {
        dataTableList.value = res.map(item => ({
          ...item,
          opd_cc: item.opd_cc.split('\n').join(''),
          opd_hpi: item.opd_hpi.split('\n').join(''),
          opd_pmh: item.opd_pmh.split('\n').join(''),
          date_now: `${`( ${i18n.t('print_date')} : ${sumdatetime(
            moment().format(''),
            i18n.locale,
          )} )`}`,
          date_now2: `${i18n.t('date')} ${sumdateUpper(item.opd_date, i18n.locale)}`,
          customer_age: `${i18n.t('age')} ${item.customer_age}`,
          customer_birthdate: item.customer_birthdate || '',
          check_list: item.check_list || [],
          course_list: item.course_list || [],
          drug_list: item.drug_list || [],
        }))
        savePDF(dataTableList.value)
      })
    }

    const savePDF = data => {
      const chkAdd = false
      const doc = new jsPDF('p', 'mm', 'a4')
      doc.addFileToVFS('TH-Niramit-AS-bold.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-bold.ttf', 'TH-Niramit', 'bold')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')
      doc.setTextColor('#000')
      doc.setProperties({
        title: `OPD CARD - HN ${data[0].customer_id}`,
      })
      for (let a = 0; a < data.length; a++) {
        const spacer = 4
        let totalL = 5
        let totalR = 5
        const starterL = 5
        const starterR = 115
        const starterRHeader = 205
        doc.autoTable({
          didDrawPage(hookData) {
            const arrLeft = [
              {
                name: i18n.t('name'),
                value: `${data[a].customer_fullname} HN: ${data[a].customer_id.replaceAll('\r', '')} VN: ${data[a].opd_code}`,
              },
              {
                name: i18n.t('gender'),
                value: `${data[a].customer_gender} ${i18n.t('age')} : ${data[a].customer_age} ${i18n.t('blood_type')} : ${data[a].customer_blood}`,
              },
              {
                name: i18n.t('telephone_number'),
                value: `${data[a].customer_tel} ${i18n.t('congenital_disease')} : ${data[a].customer_disease} ${i18n.t('be_allergic')} : ${data[a].customer_allergic}`,
              },
              { name: i18n.t('address'), value: `${data[a].customer_address}` },

            ]
            const arrRight = [
              { name: '', value: `${data[a].date_now}` },
              { name: '', value: `${data[a].date_now2}` },
              { name: i18n.t('license_number'), value: `${data[a].shop_license}` },
              { name: i18n.t('nursing_address'), value: `${data[a].shop_address}` },
              { name: i18n.t('telephone_number'), value: `${data[a].shop_tel}` },
            ]
            const arrCheck1 = [
              { name: 'T: ', value: `${data[a].opd_t}`, unit: 'C.' },
              { name: 'P: ', value: `${data[a].opd_p}`, unit: '/min.' },
              { name: 'RR: ', value: `${data[a].opd_rr}`, unit: '/min.' },
              { name: 'BP: ', value: `${data[a].opd_bp}`, unit: 'mmHg' },
            ]
            const arrCheck2 = [
              { name: i18n.t('weight'), value: `${data[a].opd_w}`, unit: i18n.t('kg') },
              { name: i18n.t('height'), value: `${data[a].opd_h}`, unit: i18n.t('cm') },
              { name: 'BMI: ', value: `${data[a].opd_bmi}`, unit: '' },
              { name: 'FBS: ', value: `${data[a].opd_fbs}`, unit: 'mg/dl' },
            ]
            hookData.doc.addImage(JSON.parse(localStorage.getItem('shopImage')), 'JPEG', 7, 6, 18, 17)
            hookData.doc.setFontSize(16)
            hookData.doc.setFillColor('#000')
            hookData.doc.text(`${shopData.value.shop_name} ${shopData.value.shop_nature}`, 75, 8, { align: 'center' })
            hookData.doc.setFont('TH-Niramit', 'bold')
            hookData.doc.setFontSize(12)
            hookData.doc.text(`${i18n.t('address')} ${shopData.value.shop_address} ${i18n.t('reportpsychotropic2_name_7')} ${shopData.value.shop_district}`, 75, 14, { align: 'center' })
            hookData.doc.text(`${i18n.t('reportpsychotropic2_name_8')} ${shopData.value.shop_amphoe}  ${shopData.value.shop_province}  ${shopData.value.shop_zipcode} `, 75, 20, { align: 'center' })
            hookData.doc.text(`${i18n.t('tel')} ${data[a].shop_tel}`, 70, 26, { align: 'center' })
            hookData.doc.setFontSize(18)
            hookData.doc.text(`NH ${data[a].customer_id} `, 207, 8, { align: 'right' })
            hookData.doc.setFontSize(12)
            hookData.doc.text(`${i18n.t('id_card')}`, 130, 13)
            doc.setLineWidth(0.1)
            doc.rect(130, 15, 5, 5)
            doc.rect(136, 15, 5, 5)
            doc.rect(142, 15, 5, 5)
            doc.rect(148, 15, 5, 5)
            doc.rect(154, 15, 5, 5)
            doc.rect(160, 15, 5, 5)
            doc.rect(166, 15, 5, 5)
            doc.rect(172, 15, 5, 5)
            doc.rect(178, 15, 5, 5)
            doc.rect(184, 15, 5, 5)
            doc.rect(190, 15, 5, 5)
            doc.rect(196, 15, 5, 5)
            doc.rect(202, 15, 5, 5)
            hookData.doc.setFontSize(13)
            if (data[a].customer_idcard) {
              hookData.doc.text(`${data[a].customer_idcard.substr(0, 1)}`, 131.8, 18.5)
              hookData.doc.text(`${data[a].customer_idcard.substr(1, 1)}`, 137.8, 18.5)
              hookData.doc.text(`${data[a].customer_idcard.substr(2, 1)}`, 143.8, 18.5)
              hookData.doc.text(`${data[a].customer_idcard.substr(3, 1)}`, 149.8, 18.5)
              hookData.doc.text(`${data[a].customer_idcard.substr(4, 1)}`, 155.8, 18.5)
              hookData.doc.text(`${data[a].customer_idcard.substr(5, 1)}`, 161.8, 18.5)
              hookData.doc.text(`${data[a].customer_idcard.substr(6, 1)}`, 167.8, 18.5)
              hookData.doc.text(`${data[a].customer_idcard.substr(7, 1)}`, 173.8, 18.5)
              hookData.doc.text(`${data[a].customer_idcard.substr(8, 1)}`, 179.8, 18.5)
              hookData.doc.text(`${data[a].customer_idcard.substr(9, 1)}`, 185.8, 18.5)
              hookData.doc.text(`${data[a].customer_idcard.substr(10, 1)}`, 191.8, 18.5)
              hookData.doc.text(`${data[a].customer_idcard.substr(11, 1)}`, 197.8, 18.5)
              hookData.doc.text(`${data[a].customer_idcard.substr(12, 1)}`, 203.8, 18.5)
            }
            hookData.doc.setFontSize(12)
            hookData.doc.text(`${i18n.t('dateBrith')}`, 153, 25.5, { align: 'right' })
            doc.setLineWidth(0.1)
            doc.rect(154, 22, 5, 5)
            doc.rect(160, 22, 5, 5)
            doc.rect(169, 22, 5, 5)
            doc.rect(175, 22, 5, 5)
            doc.rect(184, 22, 5, 5)
            doc.rect(190, 22, 5, 5)
            doc.rect(196, 22, 5, 5)
            doc.rect(202, 22, 5, 5)
            hookData.doc.text(`${data[a].customer_birthdate.substr(8, 1)}`, 155.8, 25.5)
            hookData.doc.text(`${data[a].customer_birthdate.substr(9, 1)}`, 161.8, 25.5)
            hookData.doc.text(`${data[a].customer_birthdate.substr(5, 1)}`, 170.8, 25.5)
            hookData.doc.text(`${data[a].customer_birthdate.substr(6, 1)}`, 176.8, 25.5)
            hookData.doc.text(`${data[a].customer_birthdate.substr(0, 1)}`, 185.8, 25.5)
            hookData.doc.text(`${data[a].customer_birthdate.substr(1, 1)}`, 191.8, 25.5)
            hookData.doc.text(`${data[a].customer_birthdate.substr(2, 1)}`, 197.8, 25.5)
            hookData.doc.text(`${data[a].customer_birthdate.substr(3, 1)}`, 203.8, 25.5)

            hookData.doc.setLineWidth(0.5)
            hookData.doc.setDrawColor('#000')
            hookData.doc.line(61, 28, 207, 28)
            hookData.doc.setDrawColor('#000')
            hookData.doc.line(3, 28, 60, 28)
            hookData.doc.setFontSize(13)
            let myTotal = 35
            let count = 6
            hookData.doc.text(`${i18n.t('name')}..................................................${i18n.t('lastname')}..........................................................................${i18n.t('gender')}................${i18n.t('tel')}................................................`, 4, myTotal)
            hookData.doc.text(`${data[a].customer_fname}`, 10, myTotal - 0.5)
            hookData.doc.text(`${data[a].customer_lname}`, 70, myTotal - 0.5)
            hookData.doc.text(`${data[a].customer_age}`, 100, myTotal - 0.5)
            hookData.doc.text(`${data[a].customer_gender}`, 147, myTotal - 0.5)
            hookData.doc.text(`${data[a].customer_tel}`, 167, myTotal - 0.5)
            hookData.doc.text(``, 50, myTotal - 0.5)
            myTotal += count
            hookData.doc.text(`${i18n.t('congenital_disease')}................................................${i18n.t('be_allergic')}...............................................${i18n.t('oral medication')}........................ID LINE....................................`, 4, myTotal)
            hookData.doc.text(`${data[a].customer_disease}`, 25, myTotal - 0.5)
            hookData.doc.text(`${data[a].customer_allergic}`, 80, myTotal - 0.5)
            myTotal += count
            hookData.doc.text(`${i18n.t('address')}..........................................................................................................................................${i18n.t('nationality')}...........................................................`, 4, myTotal)
            hookData.doc.text(`${data[a].customer_address}`, 13, myTotal - 0.5)
            myTotal += count
            hookData.doc.text(`${i18n.t('Emergency Contact Name')}..............................................................................................................${i18n.t('telephone number')}...........................................................`, 4, myTotal)
            myTotal += count
            hookData.doc.setFontSize(14)
            hookData.doc.text(`${i18n.t('Checklist and treatment')}`, 105, myTotal, { align: 'center' })
            myTotal += count
            hookData.doc.text(`BP........./.........mmHg BT.........C  PR........./min Weight.........kg Height........./cm.`, 105, myTotal, { align: 'center' })
            hookData.doc.text(`${data[a].opd_bp}`, 48, myTotal - 0.5)
            hookData.doc.text(`${data[a].opd_t}`, 83, myTotal - 0.5)
            hookData.doc.text(`${data[a].opd_p}`, 100, myTotal - 0.5)
            hookData.doc.text(`${data[a].opd_w}`, 128.5, myTotal - 0.5)
            hookData.doc.text(`${data[a].opd_h}`, 152, myTotal - 0.5)
            myTotal += count - 3
            doc.setLineWidth(0.1)
            doc.rect(8, myTotal, 96, 75)
            doc.rect(106, myTotal, 96, 75)
            myTotal += count
            hookData.doc.text(`${i18n.t('date')}...........................................${i18n.t('course')}..................................`, 9, myTotal)
            hookData.doc.text(`${i18n.t('date')}...........................................${i18n.t('course')}..................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`${i18n.t('do the times')}................................................................................`, 9, myTotal)
            hookData.doc.text(`${i18n.t('do the times')}................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`HX........................................................................................`, 9, myTotal)
            hookData.doc.text(`HX........................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`RX........................................................................................`, 9, myTotal)
            hookData.doc.text(`RX........................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count - 2
            doc.setLineWidth(0.1)
            doc.rect(8, myTotal, 96, 75)
            doc.rect(106, myTotal, 96, 75)
            myTotal += count
            hookData.doc.text(`${i18n.t('date')}...........................................${i18n.t('course')}..................................`, 9, myTotal)
            hookData.doc.text(`${i18n.t('date')}...........................................${i18n.t('course')}..................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`${i18n.t('do the times')}................................................................................`, 9, myTotal)
            hookData.doc.text(`${i18n.t('do the times')}................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`HX........................................................................................`, 9, myTotal)
            hookData.doc.text(`HX........................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`RX........................................................................................`, 9, myTotal)
            hookData.doc.text(`RX........................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count - 1
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count - 1
            doc.rect(8, myTotal, 96, 75)
            doc.rect(106, myTotal, 96, 75)
            myTotal += count
            hookData.doc.text(`${i18n.t('date')}...........................................${i18n.t('course')}..................................`, 9, myTotal)
            hookData.doc.text(`${i18n.t('date')}...........................................${i18n.t('course')}..................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`${i18n.t('do the times')}................................................................................`, 9, myTotal)
            hookData.doc.text(`${i18n.t('do the times')}................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`HX........................................................................................`, 9, myTotal)
            hookData.doc.text(`HX........................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`RX........................................................................................`, 9, myTotal)
            hookData.doc.text(`RX........................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            doc.addPage()
            myTotal = 5
            doc.setLineWidth(0.1)
            doc.rect(8, myTotal, 96, 75)
            doc.rect(106, myTotal, 96, 75)
            myTotal += count
            hookData.doc.text(`${i18n.t('date')}...........................................${i18n.t('course')}..................................`, 9, myTotal)
            hookData.doc.text(`${i18n.t('date')}...........................................${i18n.t('course')}..................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`${i18n.t('do the times')}................................................................................`, 9, myTotal)
            hookData.doc.text(`${i18n.t('do the times')}................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`HX........................................................................................`, 9, myTotal)
            hookData.doc.text(`HX........................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`RX........................................................................................`, 9, myTotal)
            hookData.doc.text(`RX........................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count - 2
            doc.setLineWidth(0.1)
            doc.rect(8, myTotal, 96, 75)
            doc.rect(106, myTotal, 96, 75)
            myTotal += count
            hookData.doc.text(`${i18n.t('date')}...........................................${i18n.t('course')}..................................`, 9, myTotal)
            hookData.doc.text(`${i18n.t('date')}...........................................${i18n.t('course')}..................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`${i18n.t('do the times')}................................................................................`, 9, myTotal)
            hookData.doc.text(`${i18n.t('do the times')}................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`HX........................................................................................`, 9, myTotal)
            hookData.doc.text(`HX........................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`RX........................................................................................`, 9, myTotal)
            hookData.doc.text(`RX........................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count - 1
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count - 1
            doc.rect(8, myTotal, 96, 75)
            doc.rect(106, myTotal, 96, 75)
            myTotal += count
            hookData.doc.text(`${i18n.t('date')}...........................................${i18n.t('course')}..................................`, 9, myTotal)
            hookData.doc.text(`${i18n.t('date')}...........................................${i18n.t('course')}..................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`${i18n.t('do the times')}................................................................................`, 9, myTotal)
            hookData.doc.text(`${i18n.t('do the times')}................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`HX........................................................................................`, 9, myTotal)
            hookData.doc.text(`HX........................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`RX........................................................................................`, 9, myTotal)
            hookData.doc.text(`RX........................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
            myTotal += count
            hookData.doc.text(`............................................................................................`, 9, myTotal)
            hookData.doc.text(`............................................................................................`, 107, myTotal)
          },
        })
        doc.addPage(1)
      }
      doc.deletePage((dataTableList.value.length * 2) + 1)
      frame.value = doc.output('datauristring', { filename: 'hello world' })
    }
    return {
      frame,
    }
  },
}
</script>
